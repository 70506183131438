<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="academicYear" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('academic_year')">
                                    <academic-years-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.academic_year">
                                    </academic-years-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="moduleId" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('module')">
                                    <module-selectbox
                                        :academic_year="defineForm.academic_year"
                                        :validateError="errors[0]"
                                        value-type="id"
                                        v-model="defineForm.module">
                                    </module-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="level" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('level')">
                                    <parameter-selectbox
                                        code="prep_levels"
                                        :validate-error="errors[0]"
                                        v-model="defineForm.level">
                                    </parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="class" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('class')">
                                    <parameter-selectbox
                                        code="prep_class"
                                        :validate-error="errors[0]"
                                        v-model="defineForm.class">
                                    </parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>


                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from 'vee-validate';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
    import ModuleSelectbox from '@/components/interactive-fields/ModuleSelectbox';
    import PrepClassesService from '@/services/PrepClassesService';
    import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox';

    export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ParameterSelectbox,
        ModuleSelectbox,
        AcademicYearsSelectbox

    },
    props: {
        id: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            defineForm: {
                module: null,
                level: null,
                class: null,
                academic_year: null,
                prepClassId: null
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("prep_classes_title")
        }
    },
    methods: {
        getFormData() {
            PrepClassesService.show(this.id)
                              .then(response => {
                                  let data = response.data.data
                                  this.defineForm.academic_year = data.academic_year
                                  this.defineForm.module = data.module_id
                                  this.defineForm.level = data.level
                                  this.defineForm.class = data.class
                                  this.defineForm.prepClassId = data.id
                              })
        },

        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let formData = {
                    module_id: this.defineForm.module,
                    level: this.defineForm.level,
                    class: this.defineForm.class
                }

                PrepClassesService.update(this.id, formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$emit('hide')
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$refs.updateForm.errors.moduleId.push(this.$t('api.' + e.data.message));
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.module_id) {
                            this.$refs.updateForm.errors.moduleId.push(e.data.errors.module_id[0]);
                        }
                        if (e.data.errors.level) {
                            this.$refs.updateForm.errors.level.push(e.data.errors.level[0]);
                        }
                        if (e.data.errors.class) {
                            this.$refs.updateForm.errors.class.push(e.data.errors.class[0]);
                        }
                    }
                })
            }
        }
    },

    created() {

    },

    watch: {
        id: {
            handler: function (val) {
                this.getFormData(val)
            }
        }
    }
};
</script>
